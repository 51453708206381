import _ from 'underscore';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import basedataService from '../services/basedataService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, displayValues, fuelleBetriebSelect, fuelleSelectOptionen, neueArrayRow, resetDialogInput, setMenuActive } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereUnternehmensdaten = speichereUnternehmensdaten;
window.myHandlers.confirmNeueBetriebsstaetteDialog = confirmNeueBetriebsstaetteDialog;
window.myHandlers.neueBetriebsstaette = neueBetriebsstaette;
window.myHandlers.speichereBetriebsstaette = speichereBetriebsstaette;
window.myHandlers.toggleBetriebsstaette = toggleBetriebsstaette;
window.myHandlers.confirmToggleBetriebDialog = confirmToggleBetriebDialog;
window.myHandlers.neuerBereich = neuerBereich;
window.myHandlers.neuerPosten = neuerPosten;
window.myHandlers.confirmNeuerBereichDialog = confirmNeuerBereichDialog;
window.myHandlers.confirmNeuerPostenDialog = confirmNeuerPostenDialog;
window.myHandlers.registriereBetriebSofortmelder = registriereBetriebSofortmelder;
window.myHandlers.aktiviereBetriebSofortmelder = aktiviereBetriebSofortmelder;

// Befüllt die Oberfläche der Verwaltung der Stammdaten
async function ladeVerwaltung() {
    setMenuActive(document.getElementById('Stammdaten'), 'submenu_active');
	const unternehmen = await stammdatenService.holeUnternehmensdaten(true);
	const panelUnternehmen = document.getElementById('u-input-panel');
	const unfallV = await basedataService.holeUVBasedataProvider();
	const bundeslaender = await basedataService.holeBundeslaenderBasedataProvider();
	const bundeslandSelect = panelUnternehmen.querySelector('[aria-label="u-input-Bundesland"');
	fuelleSelectOptionen(bundeslandSelect, bundeslaender, 'Bundesland', 'Bundesland');
    displayValues(_.omit(unternehmen, 'Betriebsstaette'), '', document.getElementById('u-input-panel'), 'u-input-');
	// Bereichliste rerendern...
	document.body.querySelectorAll('.bereiche-liste').forEach((b) => b.remove());
    displayArray(unternehmen.Bereiche, 'u-input-bereiche-', panelUnternehmen);
	// Reset alte Liste an Betriebsstätten
	const activeBS = document.body.querySelectorAll('.bs-panel-container > .accordion.active');
	const activeBSIDs = [];
	activeBS.forEach((bs) => activeBSIDs.push(bs.parentNode.id));

	document.body.querySelectorAll('.bs-panel-container').forEach((elem) => elem.remove());
	// Lade alle Betriebsstätten sortiert nach Aktiv/Inkaktiv
	unternehmen.Betriebsstaette.sort((a, b) => Number(b.Aktiv) - Number(a.Aktiv));
    unternehmen.Betriebsstaette.forEach((bs) => {
		const template = document.querySelector('[bs-panel-template]');
        const newPanel = template.content.cloneNode(true).children[0];
		newPanel.id = `${bs._id}-panel`;
        newPanel.querySelector('[bs-button-header]').innerText = `Betriebsstätte: ${bs.BetriebsstaetteName}`;
		const toggleButton = newPanel.querySelector('[aria-label="bs-input-toggle"]');
		toggleButton.id = bs._id;
		if (bs.Aktiv) {
			toggleButton.classList.add('entfernen-button');
			toggleButton.innerText = 'Betriebsstätte deaktivieren';
		} else {
			newPanel.querySelector('[bs-button-header]').innerText += ' (inaktiv)';
			toggleButton.classList.add('verwenden-button');
			toggleButton.innerText = 'Betriebsstätte reaktivieren';
		}
		const unfallSelect = newPanel.querySelector('[aria-label="bs-input-GesetzlicheUV"');
		fuelleSelectOptionen(unfallSelect, unfallV, 'BNR-UV', 'NAME');
		const bundeslandSelectBS = newPanel.querySelector('[aria-label="bs-input-Bundesland"');
		fuelleSelectOptionen(bundeslandSelectBS, bundeslaender, 'Bundesland', 'Bundesland');
        displayValues(bs, '', newPanel, 'bs-input-');
        displayArray(bs.Posten, 'bs-input-posten-', newPanel);
		displayValues(bs.Sofortmeldung, '', newPanel, 'bs-input-Sofortmeldung-');
        document.getElementById('bs-create-button').insertAdjacentElement('beforebegin', newPanel);
		const sofortmelderActivationCode = newPanel.querySelector('[aria-label="bs-input-Sofortmeldung-SofortmelderActivationCode"]');
		const sofortmelderActivationButton = newPanel.querySelector('[aria-label="bs-input-Sofortmeldung-SofortmelderActivationButton"]');
		sofortmelderActivationButton.disabled = sofortmelderActivationCode.value.length != 23;
    });
	// aufgeklappte Betriebsstätte aufgeklappt lassen
	activeBSIDs.forEach((bs) => {
		const bsPanel = document.getElementById(bs);
		if (bsPanel) {
			window.myHandlers.toggleAccordion(bsPanel.querySelector('.accordion'));
		}
	});
}

function confirmNeueBetriebsstaetteDialog() {
	SystemDialogService.instance.confirm({
		bsName: document.getElementById('neu-betrieb-name').value,
	});
}

// Button Control, um eine neue Betriebsstätte anzulegen.
async function neueBetriebsstaette(thisElement) {
	const input = await SystemDialogService.instance.displayAsync('neu-betrieb-dialog');
	// vermeide leere Einträge
	if (!input.success) {
		return;
	}
	if (input.data.bsName === '') {
		systemNachrichtService.zeigeKleineNachricht('Abbruch: Keine Bezeichnung angegeben.', 0);
		return;
	}
	// Wir schicken die neue Betriebsstätte an den Server und erstellen die Oberfläche
	const result = await stammdatenService.erstelleBetriebsstaette(input.data.bsName);
	if (!result) {
		return;
	}
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const unfallV = await basedataService.holeUVBasedataProvider();
	const bundeslaender = await basedataService.holeBundeslaenderBasedataProvider();
	const template = document.querySelector('[bs-panel-template]');
    const newPanel = template.content.cloneNode(true).children[0];
	const betrieb = unternehmen.Betriebsstaette.find((bs) => bs.BetriebsstaetteName === input.data.bsName);
	newPanel.querySelector('[bs-button-header]').innerText = `Betriebsstätte: ${betrieb.BetriebsstaetteName}`;
	const toggleButton = newPanel.querySelector('[aria-label="bs-input-toggle"]');
	toggleButton.id = betrieb._id;
	toggleButton.innerText = 'Betriebsstätte deaktiveren';
	toggleButton.classList.add('entfernen-button');
	const unfallSelect = newPanel.querySelector('[aria-label="bs-input-GesetzlicheUV"');
	fuelleSelectOptionen(unfallSelect, unfallV, 'BNR-UV', 'NAME');
	const bundeslandSelectBS = newPanel.querySelector('[aria-label="bs-input-Bundesland"');
	fuelleSelectOptionen(bundeslandSelectBS, bundeslaender, 'Bundesland', 'Bundesland');
	displayValues(betrieb, '', newPanel, 'bs-input-');
	displayArray(betrieb.Posten, 'bs-input-posten-', newPanel);
	newPanel.id = `${betrieb._id}-panel`;
	thisElement.insertAdjacentElement('beforebegin', newPanel);
	// Zeige neue BS im Select Menü
	await fuelleBetriebSelect();
}

/**
 * Liest die Daten aus der Oberfläche und ersetzt die Stammdaten des Unternehmens
 */
async function speichereUnternehmensdaten() {
	const prefix = 'u-input-';
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const panel = document.getElementById(`${prefix}panel`);
	// Holt alle normalen Input Werte
	collectValues(unternehmen, prefix, panel);

	// Bereiche speichern
	unternehmen.Bereiche.forEach((bereich) => {
		// wir suchen die Bereich ID im Frontend und die zugehörigen Inputfelder.
		const zeile = panel.querySelector(`[aria-label="${bereich._id}"]`);
		const zeileBereich = zeile.querySelector(`[aria-label="${prefix}bereiche-Bereich"]`).value;
		const zeileHauptkostenstelle = zeile.querySelector(`[aria-label="${prefix}bereiche-Hauptkostenstelle"]`).value;
		const zeileVerwenden = zeile.querySelector(`[aria-label="${prefix}bereiche-Verwenden"]`).checked;
		bereich.Bereich = zeileBereich;
		bereich.Hauptkostenstelle = zeileHauptkostenstelle;
		bereich.Verwenden = zeileVerwenden;
	});
	updateDebounceUnternehmen(unternehmen);
}

// Liest die Stammdaten der Betriebsstätte aus der Oberfläche und ersetzt diese mit neuen Daten.
async function speichereBetriebsstaette(thisElement) {
	// Das nächste Panel Container Element sollte zu der Betriebsstätte gehören
	const panelID = thisElement.closest('.bs-panel-container').id;
	const bsID = panelID.split('-')[0];
	const prefix = 'bs-input-';
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bsIndex = unternehmen.Betriebsstaette.findIndex((bs) => bs._id === bsID);
	// Falls die ID nicht mit einer Betriebsstätte übereinstimmt, gibt es diese nicht und wir brechen ab...
	if (bsIndex < 0) {
		return;
	}
	const betriebsstaette = unternehmen.Betriebsstaette[bsIndex];
	const panel = document.getElementById(panelID);
	// Holt alle normalen Input Werte
	collectValues(betriebsstaette, prefix, panel);

	// Posten speichern
	betriebsstaette.Posten.forEach((posten) => {
		// wir suchen die Posten ID im Frontend und die zugehörigen Inputfelder.
		const zeile = panel.querySelector(`[aria-label="${posten._id}"]`);
		const zeilePosten = zeile.querySelector(`[aria-label="${prefix}posten-Posten"]`).value;
		const zeileKostenstelle = zeile.querySelector(`[aria-label="${prefix}posten-Kostenstelle"]`).value;
		const zeileBereichID = zeile.querySelector(`[aria-label="${prefix}posten-BereichID"]`).value;
		const zeileVerwenden = zeile.querySelector(`[aria-label="${prefix}posten-Verwenden"]`).checked;
		posten.Posten = zeilePosten;
		posten.Kostenstelle = zeileKostenstelle;
		posten.BereichID = zeileBereichID;
		posten.Verwenden = zeileVerwenden;
	});

	const sofortmelderActivationCode = panel.querySelector('[aria-label="bs-input-Sofortmeldung-SofortmelderActivationCode"]');
	betriebsstaette.Sofortmeldung.SofortmelderActivationCode = sofortmelderActivationCode.value.toUpperCase();
	sofortmelderActivationCode.value = sofortmelderActivationCode.value.toUpperCase();
	// Button freigeben...
	if (sofortmelderActivationCode) {
		const sofortmelderActivationButton = panel.querySelector('[aria-label="bs-input-Sofortmeldung-SofortmelderActivationButton"]');
		setTimeout(() => {
			sofortmelderActivationButton.disabled = sofortmelderActivationCode.value.length != 23;
		}, 1000);
	}
	unternehmen.Betriebsstaette[bsIndex] = betriebsstaette;
	updateDebounceUnternehmen(unternehmen);
}

function confirmToggleBetriebDialog() {
	SystemDialogService.instance.confirm();
}

/**
 * Setzt eine Betriebsstätte als nicht verwendet bzw. ungültig.
 * @param {HTMLElement} thisElement Entfernen Button, der die ID der Betriebsstätte enthält.
 */
async function toggleBetriebsstaette(thisElement) {
	let unternehmen = await stammdatenService.holeUnternehmensdaten();
	// Wir benutzen vorsichtshalber eine Kopie, damit wir nicht später Inkonsitenzen bei einem Fehler haben
	unternehmen = JSON.parse(JSON.stringify(unternehmen));
	const bsIndex = unternehmen.Betriebsstaette.findIndex((bs) => bs._id === thisElement.id);
	if (bsIndex < 0) {
		systemNachrichtService.zeigeKleineNachricht('Betriebsstätte konnte nicht gefunden werden!', -1);
		return;
	}
	const headerText = `Betriebsstätte '${unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName}' ${!unternehmen.Betriebsstaette[bsIndex].Aktiv ? 'aktivieren' : 'deaktivieren'}?`;
	document.getElementById('toggle-bs-header').innerText = headerText;
	const isSicherText = `Wollen Sie wirklich die Betriebsstätte '${unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName}' ${!unternehmen.Betriebsstaette[bsIndex].Aktiv ? 'wieder' : 'nicht mehr'} in Ihrem Unternhemen verwenden?`;
	document.getElementById('toggle-bs-text').innerText = isSicherText;
	const isSicher = await SystemDialogService.instance.displayAsync('toggle-bs-dialog');
	if (!isSicher.success) {
		return;
	}
	unternehmen.Betriebsstaette[bsIndex].Aktiv = !unternehmen.Betriebsstaette[bsIndex].Aktiv;
	await stammdatenService.updateUnternehmensdaten(unternehmen);
	if (!unternehmen.Betriebsstaette[bsIndex].Aktiv) {
		document.getElementById(`${thisElement.id}-panel`).querySelector('[bs-button-header]').innerText += ' (inaktiv)';
		thisElement.classList.remove('entfernen-button');
		thisElement.classList.add('verwenden-button');
		thisElement.innerText = 'Betriebsstätte reaktiveren';
	} else {
		document.getElementById(`${thisElement.id}-panel`).querySelector('[bs-button-header]').innerText = unternehmen.Betriebsstaette[bsIndex].BetriebsstaetteName;
		thisElement.classList.remove('verwenden-button');
		thisElement.classList.add('entfernen-button');
		thisElement.innerText = 'Betriebsstätte deaktivieren';
	}
	systemNachrichtService.zeigeKleineNachricht('Änderungen wurden erfolgreich übernommen!', 1);
	await fuelleBetriebSelect();
}

async function neuerBereich() {
	const result = await SystemDialogService.instance.displayAsync('neu-bereich-dialog');
	if (!result.success) {
		return;
	}
	if (result.data.BereichBezeichnung === '') {
		systemNachrichtService.zeigeKleineNachricht('Bereichsangaben unvollständig! Bitte wiederholen sie die Aktion.', 0);
		return;
	}
	const response = await stammdatenService.erstelleBereich(result.data.BereichBezeichnung, result.data.BereichNummer);
	if (!response) {
		return;
	}
	resetDialogInput('neu-bereich-dialog');
	// Aktualisierung der Oberfläche
	const prefix = 'u-input-bereiche-';
	const template = document.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = document.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

async function confirmNeuerBereichDialog() {
	SystemDialogService.instance.confirm({
		BereichBezeichnung: document.getElementById('neu-bereich-name').value,
		BereichNummer: document.getElementById('neu-bereich-nummer').value
	});
}

async function neuerPosten(thisElement) {
	// Wir wollen die Bereiche auswählen können
	const postenSelect = document.getElementById('neu-posten-bereich');
	const bereiche = stammdatenService.unternehmensobjekt.Bereiche;
	fuelleSelectOptionen(postenSelect, bereiche, '_id', 'Bereich');

	const result = await SystemDialogService.instance.displayAsync('neu-posten-dialog');
	if (!result.success) {
		return;
	}
	if (result.data.PostenBezeichnung === '') {
		systemNachrichtService.zeigeKleineNachricht('Postenangaben unvollständig! Bitte wiederholen sie die Aktion.', 0);
		return;
	}
	const betriebPanel = thisElement.closest('.bs-panel-container');
	const betriebID = betriebPanel.id.split('-')[0];
	const response = await stammdatenService.erstellePosten(result.data.PostenBezeichnung, result.data.PostenBereich, result.data.PostenNummer, betriebID);
	if (!response) {
		return;
	}
	resetDialogInput('neu-posten-bereich');
	// Aktualisierung der Oberfläche
	const prefix = 'bs-input-posten-';
	const template = betriebPanel.querySelector(`[${prefix}template]`);
	// Wir brauchen ein Referenzelement, wo wir die neuen Zeilen im HTML einfügen können
	const referenceElement = betriebPanel.querySelector(`[${prefix}button]`);
	neueArrayRow(template, referenceElement, response, prefix);
}

async function confirmNeuerPostenDialog() {
	SystemDialogService.instance.confirm({
		PostenBezeichnung: document.getElementById('neu-posten-name').value,
		PostenBereich: document.getElementById('neu-posten-bereich').value,
		PostenNummer: document.getElementById('neu-posten-nummer').value
	});
}

async function registriereBetriebSofortmelder(thisElement) {
	// Das nächste Panel Container Element sollte zu der Betriebsstätte gehören
	const panelID = thisElement.closest('.bs-panel-container').id;
	const bsID = panelID.split('-')[0];	
	try {
		SystemDialogService.instance.display('u-sofortmeldung-loading-dialog', { closeOnBackdrop: false });
		const response = await fetch('/neolohn/api/unternehmen/betriebsstaette/sofortmeldung/request', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				BetriebsstaetteID: bsID
			})
		});
		if (!response.ok) {
			const msg = await response.text();
			systemNachrichtService.zeigeKleineNachricht(msg);
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Sofortmeldung Mandat angefragt!', 1);
		SystemDialogService.instance.confirm();
		await SystemDialogService.instance.displayAsync('u-sofortmeldung-registrieren-dialog');
		await ladeVerwaltung();
	} catch (error) {
		console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Anfrage fehlgeschlagen!', -1);
		SystemDialogService.instance.confirm();
		await ladeVerwaltung();
	}
}
async function aktiviereBetriebSofortmelder(thisElement) {
	// Das nächste Panel Container Element sollte zu der Betriebsstätte gehören
	const panelID = thisElement.closest('.bs-panel-container').id;
	const bsID = panelID.split('-')[0];	
	try {
		SystemDialogService.instance.display('u-sofortmeldung-loading-dialog', { closeOnBackdrop: false });
		const response = await fetch('/neolohn/api/unternehmen/betriebsstaette/sofortmeldung/confirm', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				BetriebsstaetteID: bsID
			})
		});
		if (!response.ok) {
			const msg = await response.text();
			systemNachrichtService.zeigeKleineNachricht(msg);
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Sofortmeldung Mandat aktiviert!', 1);
		SystemDialogService.instance.confirm();
		await SystemDialogService.instance.displayAsync('u-sofortmeldung-aktivieren-dialog');
		await ladeVerwaltung();
	} catch (error) {
		console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Anfrage fehlgeschlagen!', -1);
		SystemDialogService.instance.confirm();
		await ladeVerwaltung();
	}
}

export {
    ladeVerwaltung
};
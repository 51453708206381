import _ from 'underscore';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayArray, displayValues, fuelleSelectOptionen } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereAppEinstellungen = speichereAppEinstellungen;
window.myHandlers.speichereCronjobEinstellungen = speichereCronjobEinstellungen;
window.myHandlers.speichereUnternehmenStandards = speichereUnternehmenStandards;
window.myHandlers.clickNeuesVerzeichnis = clickNeuesVerzeichnis;
window.myHandlers.confirmNeuesVerzeichnisDialog = confirmNeuesVerzeichnisDialog;
window.myHandlers.speicherePostfachVerzeichnisse = speicherePostfachVerzeichnisse;
window.myHandlers.clickEntferneVerzeichnis = clickEntferneVerzeichnis;
window.myHandlers.freigabeVerzeichnis = freigabeVerzeichnis;

/**
 * lädt/speichert die Einstellungen der Apps
 */
async function ladeAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	displayValues(unternehmen.MitarbeiterAppEinstellungen, '', panelEinstellungen, 'u-');
}

async function speichereAppEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-app');
	collectValues(unternehmen.MitarbeiterAppEinstellungen, 'u-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

async function ladeCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	displayValues(unternehmen.NeolohnEinstellungen, '', panelEinstellungen, 'u-Cronjob-');
}

async function speichereCronjobEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-cronjob');
	collectValues(unternehmen.NeolohnEinstellungen, 'u-Cronjob-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Funktionen/Event Handlers für die Verzeichnisverwaltung
 */
async function ladePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Wir zeigen hier nur die zusätzlichen Verzeichnisse an
	displayArray(unternehmen.Verzeichnisse.filter((vz) => vz.SystemVerzeichnis && vz.VerzeichnisName !== 'Papierkorb'), 'u-system-verzeichnis-', panelEinstellungen);
	// Wir zeigen hier nur die zusätzlichen Verzeichnisse an
	displayArray(unternehmen.Verzeichnisse.filter((vz) => !vz.SystemVerzeichnis), 'u-zusatz-verzeichnis-', panelEinstellungen);
}

async function speicherePostfachVerzeichnisse() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-verzeichnisse');
	// Verzeichnisse speichern
	unternehmen.Verzeichnisse.forEach((verzeichnis) => {
		if (!verzeichnis.SystemVerzeichnis) {
			// wir suchen die VerzeichnisID im Frontend und die zugehörigen Inputfelder.
			const zeile = panelEinstellungen.querySelector(`[aria-label="${verzeichnis._id}"]`);
			const zeileVerzeichnisName = zeile.querySelector('[aria-label="u-zusatz-verzeichnis-VerzeichnisName"]').value;
			// einen leeren String wollen wir nicht als Namen haben
			verzeichnis.VerzeichnisName = zeileVerzeichnisName === '' ? verzeichnis.VerzeichnisName : zeileVerzeichnisName;
		}
	});
	updateDebounceUnternehmen(unternehmen);
}

async function clickNeuesVerzeichnis() {
	const input = await SystemDialogService.instance.displayAsync('neu-verzeichnis-dialog');
	if (input.success) {
		const result = await stammdatenService.erstellePostfachVerzeichnis(input.data);
		if (result) {
			await ladePostfachVerzeichnisse();
		}
	}
}

async function confirmNeuesVerzeichnisDialog() {
	const verzeichnisName = document.getElementById('neu-verzeichnis-name').value;
	SystemDialogService.instance.confirm(verzeichnisName);
}

async function clickEntferneVerzeichnis(thisElement) {
	const verzeichnisID = thisElement.parentNode.getAttribute('aria-label');
	const result = await stammdatenService.entfernePostfachVerzeichnis(verzeichnisID);
	if (result) {
		await ladePostfachVerzeichnisse();
	}
}

async function ladeMitarbeiterStandards() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-standard');
	const select = panelEinstellungen.querySelector('[aria-label="u-standard-PausenmodellStandard"]');
	fuelleSelectOptionen(select, unternehmen.Pausenmodelle, '_id', 'Bezeichnung', true, 'keins');
	displayValues(
		_.pick(unternehmen, [
			'SFNKappen',
			'SFNMindestlohnrelevant',
			'SFNAusgleichsbetragSVpflichtig',
			'N6ZuschlagZusatz',
			'GutzeitAutomatisierung',
			'AutomatischeProbezeitBis',
			'PausenmodellStandard',
			'UrlaubAmFeiertag'
		]),
		'',
		panelEinstellungen,
		'u-standard-'
	);
}

async function speichereUnternehmenStandards() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-standard');
	collectValues(unternehmen, 'u-standard-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}
async function ladeAlleEinstellungen() {
	await ladeAppEinstellungen();
	await ladeCronjobEinstellungen();
	await ladePostfachVerzeichnisse();
	await ladeMitarbeiterStandards();
}

/**
 * Sendet die Anfrage an des Server, das ausgewählte Verzeichnis freizugeben (oder die Freigabe zu widerrufen).
 * - Gibt alle im Verzeichnis unterliegenden Dateien frei, bzw. nicht mehr frei
 * @param {HTMLElement} thisElement input[type=checkbox]
 */
async function freigabeVerzeichnis(thisElement) {
    const verzeichnisID = _.last(thisElement.closest('.u-verzeichnis-zeile').id.split('-'));
    try {
        const response = await fetch('/neolohn/api/verzeichnis/freigeben', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                VerzeichnisID: verzeichnisID,
            })
        });
        if (!response.ok) {
			const msg = await response.text();
			systemNachrichtService.zeigeKleineNachricht(msg);
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		systemNachrichtService.zeigeKleineNachricht('Verzeichnisfreigabe aktualisiert!', 1);
		await stammdatenService.holeUnternehmensdaten(true);
    } catch (error) {
        console.log(error);
        systemNachrichtService.zeigeKleineNachricht('Aktualisierung fehlgeschlagen!', -1);
    }
}

export {
    ladeAlleEinstellungen
};

import stammdatenService from '../services/stammdatenService.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereBetriebsregeln = speichereBetriebsregeln;
window.myHandlers.speichereMitarbeiterregeln = speichereMitarbeiterregeln;


function ladeBetriebsregeln() {
    const unternehmen = stammdatenService.unternehmensobjekt;
    document.getElementById('u-betriebsregeln').value = unternehmen?.Betriebsregeln;
}

async function speichereBetriebsregeln(thisElement) {
    const unternehmen = stammdatenService.unternehmensobjekt;
    unternehmen.Betriebsregeln = thisElement.value;
    updateDebounceUnternehmen(unternehmen);
}

function ladeMitarbeiterregeln() {
    const unternehmen = stammdatenService.unternehmensobjekt;
    document.getElementById('u-mitarbeiterregeln').value = unternehmen?.Mitarbeiterregeln;
}

async function speichereMitarbeiterregeln(thisElement) {
    const unternehmen = stammdatenService.unternehmensobjekt;
    unternehmen.Mitarbeiterregeln = thisElement.value;
    updateDebounceUnternehmen(unternehmen);
}

export {
    ladeBetriebsregeln,
    ladeMitarbeiterregeln
};
